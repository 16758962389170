<section class="login-bg">
    <div class="login-main-contain">
      <div class="login-box-main">
        <div class="login-box-inner">
          <div class="login-logo">
            <img src="assets/images/moso-logo.png" alt="MOSC">
          </div>
          <div class="login-form-contain">
            <h3 class="login-box-title">{{ 'CHANGE_PASSWORD'| translate }}</h3>
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
              <div class="form-group pwd">
                <input [type]="showPassword?'text':'password'" class="form-control" formControlName="password" id="password"
                  placeholder="{{'ENTER_YOUR_NEW_PASSWORD' | translate}}">
                <span class="p-viewer">
                  <i class="fa fa-eye" aria-hidden="true" (click)='password(0)' *ngIf="!showPassword"></i>
                  <i class="fa fa-eye-slash" aria-hidden="true" (click)='password(0)' *ngIf="showPassword"></i>
                </span>
                <div class="alert alert-danger" role="alert" *ngIf="submitted && pf.password.errors">
                  {{ 'PWDERROR'| translate }}
                </div>
              </div>
              <!-- <div class="form-group pwd">
                <input [type]="showConfirmPassword?'text':'password'" class="form-control" formControlName="cpassword" id="cpassword"
                  placeholder="{{'CONFIRM_YOUR_PASSWORD' | translate}}">
                <span class="p-viewer">
                  <i class="fa fa-eye" aria-hidden="true" (click)='password(1)' *ngIf="!showConfirmPassword"></i>
                  <i class="fa fa-eye-slash" aria-hidden="true" (click)='password(1)' *ngIf="showConfirmPassword"></i>
                </span>
                <div class="alert alert-danger" role="alert" *ngIf="submitted && pf.cpassword.errors">
                  {{ 'CPWDERROR'| translate }}
                </div>
              </div> -->
              <button type="submit" class="btn btn-primary btn-blue1">{{'SUBMIT'|translate}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>