import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertMessagesService } from "../services/alert-messages.service";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  submitted = false;
  showPassword = false;
  showConfirmPassword = false;
  constructor(public auth: AuthService, public route: Router, public toastr: AlertMessagesService) {}

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      // cpassword: new FormControl(null, Validators.required),
    });
  }

  // checkPasswords(group: FormGroup) {
  //   // here we have the 'passwords' group
  //   const password = group.get("password").value;
  //   const confirmPassword = group.get("confirmPassword").value;

  //   return password === confirmPassword ? null : { notSame: true };
  // }

  get pf() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    var parent = this;
    parent.submitted = true;

    if (parent.passwordForm.invalid) {
      return;
    }

    const data = parent.passwordForm.value;
    console.log(data.password);
    this.auth
      .updatePassword(data.password)
      .then((res) => {
        console.log("here")
        this.toastr.showSuccess(
          this.auth.translate.instant("CHANGE_PASSWORD_ALERT")
        );
        this.route.navigate(["/dashboard"]);
      })
      .catch((err) => {
        this.toastr.showError(err.message);
        console.log(err);
      });
  }
  password(type: number) {
    if (type == 0) this.showPassword = !this.showPassword;
    else this.showConfirmPassword = !this.showConfirmPassword;
  }
}
