<section class="login-bg container">
    <div class="login-main-contain">
      <div class="login-box-main">
        <div class="login-box-inner">
          <div class="login-logo">
            <img src="assets/images/moso-logo.png" alt="MOSC">
          </div>
          <div class="login-form-contain">
            <h3 class="login-box-title"> {{'VERIFICATION_NOTE'|translate}}</h3>
            <button (click)="login()" class="btn btn-primary btn-green">{{'LOGIN'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>