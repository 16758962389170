import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireList, AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AlertMessagesService } from "./alert-messages.service";
import { TranslateService } from "@ngx-translate/core";
import { User } from "firebase";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isEmailVerified(type:number) {
    this.angularFireAuth.user.subscribe((user)=>{
      if(user?.emailVerified){
        if(type==0)
        this.route.navigate(["/dashboard"]);
      }
      else this.SignOut();
    })
  }
  

  constructor(
    private db: AngularFireDatabase,
    public angularFireAuth: AngularFireAuth,
    public route: Router,
    public translate: TranslateService,
    public toastr: AlertMessagesService
  ) {
    translate.addLangs(["en", "fr"]);
    if (translate.getBrowserLang()) {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
      localStorage.setItem("locale", browserLang);
    } else {
      localStorage.setItem("locale", "en");
      translate.setDefaultLang("en");
    }
  }

  get currentUser() {
    return JSON.parse(localStorage.getItem("brand_user"));
  }

  get authenticated(): boolean {
    const user = this.currentUser;
    return (user != null);
  }

  signIn(data) {
    /* Sign in */
    var parent = this;

    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.auth
        .signInWithEmailAndPassword(data.email, data.password)
        .then((res) => {
          const uid = res.user.uid;
          this.getUserById(uid)
            .then((response) => {
              this.db.database
                .ref()
                .child(`users/${uid}`)
                .once("value", function (snapshot) {
                  if (snapshot.exists() && snapshot.child("brands").exists()) {
                    resolve(res.user);
                  }
                  reject("Account Doesn't Exist");
                });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((error) => reject(error));
    });
  }

  /* Sign out */
  SignOut() {
    localStorage.clear();
    this.angularFireAuth.auth.signOut();
    this.route.navigate(["login"]);
  }

  // Creates a new record on the list, using the Realtime Database's push-ids.
  createUser(newUser) {
    const empsRef = this.db.database.ref().child("users");
    empsRef.child(newUser.id).set({
      id: newUser.id,
      email: newUser.email,
      password: newUser.password,
    });
    return true;
  }
  getUserById(uid) {
    const usersRef = this.db.database.ref().child(`users/${uid}`);
    return new Promise<any>((resolve, reject) => {
      usersRef.on(
        "value",
        function (snapshot) {
          const resData = snapshot.val();
          localStorage.setItem("brand_user", JSON.stringify(resData));
          resolve(resData);
        },
        function (err) {
          reject(err);
        }
      );
    });
  }
  sendEmail() {
    return this.angularFireAuth.auth.currentUser.sendEmailVerification();
  }
  requestAccess(request) {
    const empsRef = this.db.database.ref().child(`RequestedBrandAccounts`);
    return new Promise<any>((resolve, reject) => {
      empsRef
        .push(request)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async updateUser(uid){
  const usersRef = this.db.database.ref().child(`users/${uid}`);
  await usersRef.update({"verified":true});
}
  updatePassword(password){
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth.auth.currentUser.updatePassword(password).then((res)=>{
        this.updateUser(this.currentUser.id);
        this.updateCurrentUser();
        resolve(res);
      }).catch((err) => {
          reject(err);
        });
      
    });
     
  }
  updateCurrentUser() {
    var user=this.currentUser;
    user.verified=true;
    localStorage.setItem("brand_user", JSON.stringify(user));
  }
}
