import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort",
  pure: false,
})
export class SortPipe implements PipeTransform {
  transform(items: any, direction: string, column: string, type: string) {
    let sortedItems = [];
    sortedItems =
      direction === "desc"
        ? this.sortDescending(items, column, type)
        : this.sortAscending(items, column, type);
    return sortedItems;
  }
  sortAscending(items, column, type) {
    return [
      ...items.sort(function (a: any, b: any) {
        if (type === "string") {
          if (a[column].toUpperCase() < b[column].toUpperCase()) return -1;
        } else {
          return a[column] - b[column];
        }
      }),
    ];
  }
  sortDescending(items, column, type) {
    return [
      ...items.sort(function (a: any, b: any) {
        if (type === "string") {
          if (a[column].toUpperCase() > b[column].toUpperCase()) return -1;
        } else {
          return b[column] - a[column];
        }
      }),
    ];
  }
}
