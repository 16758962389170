import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertMessagesService {

  constructor(public toastr: ToastrService) { }

  showSuccess(msg) {
    this.toastr.success(msg, 'Success');
  }
  showError(msg) {
    this.toastr.error(msg, 'Error');
  }
  warning(msg) {
    this.toastr.error(msg, 'Warning');
  }
}
