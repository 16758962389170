import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
    private angularFireAuth: AngularFireAuth
  ) { }

  private userIds = [];

  getCartItems() {
    var ref = this.db.object("MoscCartItems").valueChanges();
    return ref;
  }
  async addCoupon(coupon, brandId) {
    const offerRef = this.db.database
      .ref()
      .child(`offers/mosc/${brandId}/${coupon.id}`);
    return offerRef.set(coupon);
  }
  async addCoupons(coupons, brandId) {
    await Promise.all(
      coupons.map(async (coupon) => {
        const ref = this.db.database
          .ref()
          .child(`CartItems/${coupon.user_id}/${coupon.cartId}`);

        ref.once("value", async snapshot => {
          if (snapshot.exists()) {
            await ref.update({ "coupon": coupon });
          } else {
            await ref.set({
              "id": coupon.cartId,
              "createdAt": new Date().getTime(),
              "position": 0, "coupon": coupon
            });
          }
        });


        const offerRef = this.db.database
          .ref()
          .child(`offers/mosc/${brandId}/${coupon.id}`);
        await offerRef.set(coupon);
        await this.sendNotification(coupon);
      })
    );
  }
  async sendNotification(coupon) {

    // registration_ids
    if (coupon.coupon == null) coupon.coupon = "";
    const userRef = this.db.database.ref().child("users");
    const userId = coupon.user_id;
    if (this.userIds.indexOf(userId) == -1) {
      this.userIds.push(userId);
      const userData = await (await userRef.child(userId).once("value")).val();
      // if (userData.device.versionCode > 30) {
      console.log("hi")
      let serverKey = environment.firebase.serverKey;
      this.http
        .post(
          "https://fcm.googleapis.com/fcm/send",
          {
            notification: {
              title: coupon.message,
              text: coupon.code,
            },
            data: {
              title: coupon.message,
              text: coupon.code,
            },
            to: userData.deviceToken,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `key=${serverKey}`,
            },
          }
        )
        .subscribe((res) => console.log(res));
      // }
    }
  }

  getBrandAccount(id) {
    var ref = this.db.object(
      this.db.database.ref().child(`users/${id}`).child("brands")
    );
    return ref.valueChanges();
  }

  getCoupons(brand) {
    var ref = this.db.object(
      this.db.database.ref().child(`offers/mosc/${brand}`)
    );
    return ref.valueChanges();
  }

  deleteCoupon(brand, coupon) {
    return new Promise<any>((resolve, reject) => {
      var ref = this.db.database
        .ref()
        .child(`offers/mosc/${brand}/${coupon.id}`);
      ref.remove((err) => {
        if (!err) {
          var couponRef = this.db.database
            .ref()
            .child(`CartItems/${coupon.user_id}/${coupon.cartId}/coupon`);
          couponRef.remove();
          resolve({ status: true });
        } else {
          reject();
        }
      });
    });
  }

  getBrand(id) {
    var ref = this.db.object(this.db.database.ref().child(`websites/${id}`));
    return ref.valueChanges();
  }
}


