import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertMessagesService } from "src/app/services/alert-messages.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  user: any;
  show = false;
  requestForm: FormGroup;
  // verifyModal: HTMLInputElement;
  requestsubmitted: boolean = false;
  constructor(
    public auth: AuthService,
    public route: Router,
    public toastr: AlertMessagesService
  ) {
    if (this.auth.authenticated) {
      this.route.navigate(["/dashboard"]);
    }
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });
    this.requestForm = new FormGroup({
      requestemail: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      name: new FormControl(null, Validators.required),
      brandname: new FormControl(null, Validators.required),
    });
  }
  get lf() {
    return this.loginForm.controls;
  }
  get rf() {
    return this.requestForm.controls;
  }

  onLoginSubmit() {
    var parent = this;
    parent.submitted = true;

    if (parent.loginForm.invalid) {
      return;
    }

    const data = parent.loginForm.value;
    parent.auth
      .signIn(data)
      .then((res) => {
        if (res.emailVerified) {
          parent.toastr.showSuccess(
            this.auth.translate.instant("USER_LOGGED_IN")
          );
          parent.route.navigate(["/dashboard"]);
        } else {
          this.sendEmail();
        }
      })
      .catch((err) => {
        if (err.message) this.toastr.showError(err.message);
        else this.toastr.showError(err);
      });
  }

  onRequestAccess() {
    this.requestsubmitted = true;
    if (this.requestForm.invalid) {
      return;
    }
    const data = this.requestForm.value;

    this.auth
      .requestAccess(data)
      .then((res) => {
        this.toastr.showSuccess(this.auth.translate.instant("REQUEST_SUBMIT"));
        this.requestForm.reset();
        Object.keys(this.rf).forEach((key) => {
          this.requestForm.get(key).setErrors(null);
        });
        this.closePopup();
      })
      .catch((err) => {
        if (err.message) this.toastr.showError(err.message);
        else this.toastr.showError(err);
      });
  }

  closePopup() {
    var popup = document.getElementById("requestModalBtn") as HTMLInputElement;
    if (popup != null) popup.click();
  }

  // click event function toggle
  password() {
    this.show = !this.show;
  }
  // declineVerification() {
  //   if (this.verifyModal != null) this.verifyModal.click();
  //   this.auth.SignOut();
  // }
  sendEmail() {
    this.auth
      .sendEmail()
      .then((res) => {
        this.route.navigate(["/verification"]);
        // if (this.verifyModal != null) this.verifyModal.click();
      })
      .catch((err) => {
        this.toastr.showError(err.message);
        // if (this.verifyModal != null) this.verifyModal.click();
      });
  }
}
