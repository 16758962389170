import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from "@angular/core";

@Directive({ selector: "[appSortParams]" })
export class SortParamsDirective {
  @Output() param: EventEmitter<any> = new EventEmitter();
  constructor(private element: ElementRef) {}

  @HostListener("click") onClickIcon() {
    this.selectSort(this.element.nativeElement.id);
  }

  selectSort(id) {
    switch (id) {
      case "newPriceAsc":
        this.param.emit({ dir: "asc", col: "newPrice", typ: "number" });
        break;
      case "priceAsc":
        this.param.emit({ dir: "asc", col: "price", typ: "number" });
        break;
      case "nameAsc":
        this.param.emit({ dir: "asc", col: "name", typ: "string" });
        break;
      case "dateAsc":
        this.param.emit({ dir: "asc", col: "updatedAt", typ: "number" });
        break;
      case "couponAsc":
        this.param.emit({ dir: "asc", col: "coupon", typ: "string" });
        break;
      case "couponDesc":
        this.param.emit({ dir: "desc", col: "coupon", typ: "string" });
        break;
        case "valDateAsc":
        this.param.emit({ dir: "asc", col: "validityDate", typ: "number" });
        break;
      case "valDateDesc":
        this.param.emit({ dir: "desc", col: "validityDate", typ: "number" });
        break;
      case "newPriceDesc":
        this.param.emit({ dir: "desc", col: "newPrice", typ: "number" });
        break;
      case "priceDesc":
        this.param.emit({ dir: "desc", col: "price", typ: "number" });
        break;
      case "dateDesc":
        this.param.emit({ dir: "desc", col: "updatedAt", typ: "number" });
        break;
      case "nameDesc":
        this.param.emit({ dir: "desc", col: "name", typ: "string" });
        break;
    }
  }
}
