export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCFJr7q_FZSEiG-9rXg8_veIClc6NnQrgI",
    authDomain: "mosc-49bf1.firebaseapp.com",
    databaseURL: "https://mosc-49bf1.firebaseio.com/",
    projectId: "mosc-49bf1",
    storageBucket: "mosc-49bf1.appspot.com",
    messagingSenderId: "586546382588",
    appId: "1:586546382588:web:ff37fcf977590dddc0f979",
    measurementId: "G-1G88DE19N9",
    serverKey:"AAAAiJDjhvw:APA91bHHFLfpFjy68gIz-wcBQ6RLJ9q5OBzuBm2-d4F2V33uxxUQ8Bp04QW1oXVRRl8B5Q0PBmxuE09KvOqBH_FgYxvPFUhGwYCFtTW3L8KtTJ_T1UGPAdIdkmO8E4M7BCYAegikm0Du"
  }
};
