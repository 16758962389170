import { formatDate } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit, Renderer2 } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertMessagesService } from "../services/alert-messages.service";
import { AuthService } from "../services/auth.service";
import { ProductService } from "../services/product.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  constructor(
    private auth: AuthService, public route: Router,
    private renderer: Renderer2,
    public product_service: ProductService,
    public toastr: AlertMessagesService
  ) {

    this.brandIndex = Number(localStorage.getItem("brandIndex") ?? 0);
  }
  user: any;
  couponForm: FormGroup;
  brandName: string = "";
  brandPosition = "";
  brandLogo: string = "";
  brandId: string = "";
  brandStartDate: any;
  brandEndDate: any;
  cartItem = [];
  coupons = [];
  brandIndex = 0;
  selectedProducts = [];
  couponApplied: boolean;
  direction: string = "";
  column: string = "";
  type: string = "";
  searchTerm = "";
  submitted = false;
  isCouponBarVisible = false;
  isSuperAdmin = false;
  allSelected = false;
  ngOnInit(): void {
    this.couponForm = new FormGroup({
      code: new FormControl(null),
      couponVisibility: new FormControl(null),
      end_date: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
    });
    this.user = this.auth.currentUser;
    // if(!this.user.verified){
    //   this.route.navigate(["/change-password"]);
    // }
    this.product_service.getBrandAccount(this.user.id).subscribe(
      (snapshot) => {
        var brands = [];
        if (snapshot) {
          Object.values(snapshot).map((brand) => {
            brands.push(brand);
          });
        }
        this.isSuperAdmin = brands.length > 1;
        this.user.brands = brands;
        this.setBrandData();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  setBrandData() {
    this.brandId = this.user.brands[this.brandIndex].brand;
    this.product_service.getCoupons(this.brandId).subscribe(
      (snapshot) => {
        if (snapshot != null) this.coupons = Object.values(snapshot);
        else this.coupons = [];
      },
      (error) => {
        this.coupons = [];
      }
    );
    this.product_service.getBrand(this.brandId).subscribe(
      (snapshot) => {
        this.user.brand = snapshot;
        this.brandName = this.user.brand.name;
        this.brandPosition = this.user.brand.defaultPosition;
        this.brandLogo = this.user.brand.icon;
        this.brandStartDate = this.user.brand.promotionStartDate;
        this.brandEndDate = this.user.brand.promotionEndDate;
        this.cartItems();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  get cf() {
    return this.couponForm.controls;
  }
  setSortParams(param) {
    this.direction = param.dir;
    this.column = param.col;
    this.type = param.typ;
  }

  updateItems() {
    this.setBrandData();
  }
  cartItems() {
    const parent = this;
    parent.cartItem = [];
    this.product_service.getCartItems().subscribe(
      (snapshot) => {
        {

          var res = snapshot;
          parent.cartItem = [];

          Object.keys(res).forEach(function (key) {
            var cartItem = res[key];
            var baseUrl: String | null = cartItem["baseUrl"];
            var cartId: String | null | undefined = cartItem["id"];
            var price: Number = cartItem["id"] ?? 0;
            if (
              parent.user.brand.url.includes(baseUrl) &&
              (cartId != undefined && cartId != null && cartId != "") &&
              price != 0
            ) {
              // if (obj.newPrice == 0) obj.newPrice = obj.price;
              cartItem["user_id"] = key;
              parent.cartItem.push(cartItem);
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  logout() {
    this.auth.SignOut();
    this.toastr.showSuccess(this.auth.translate.instant("USER_LOGGED_OUT"));
  }
  setCoupon(notificationId) {
    let notification = this.coupons.find((value) => value.id == notificationId);
    this.couponForm.patchValue({
      code: notification["code"],
      end_date: formatDate(
        new Date(notification["end_date"]),
        "yyyy-MM-dd",
        "en"
      ),
      message: notification["message"],
    });
  }


  selectedItemWithUserIds(cartId, itemBelongsToUsers) {
    if (itemBelongsToUsers && itemBelongsToUsers.length > 0) {
      itemBelongsToUsers.forEach((userId) => {
        this.selectedProducts.push({ cartId: cartId, user_id: userId });
      });
    }
  }

  selectProduct(event, id, itemBelongsToUsers) {
    if (event.target.checked) {
      this.selectedItemWithUserIds(id, itemBelongsToUsers)
    } else
      this.selectedProducts = this.selectedProducts.filter(
        (x) => x.cartId != id
      );
  }


  selectAll(event) {
    this.allSelected = event.target.checked;
    if (this.allSelected) {
      this.cartItem.map((x) => {
        this.selectedItemWithUserIds(x.id, x.itemBelongsToUsers);
      });
    } else {
      this.selectedProducts = [];
    }
  }

  onCouponSubmit() {
    this.submitted = true;
    var coupon_visibility_all = true;
    const data = this.couponForm.value;
    if (data.couponVisibility)
      coupon_visibility_all = data.couponVisibility;

    if (this.couponForm.invalid) {
      return;
    } else if (!this.selectedProducts.length && !coupon_visibility_all) {
      this.toastr.showError("Select atleast one Product");
      return;
    }
    data.end_date = new Date(data.end_date).getTime();
    data.start_date = new Date().getTime();
    data.image_url = this.brandLogo;
    data.affiliate_link = this.user.brands[this.brandIndex].brand_url;
    data.url = this.user.brands[this.brandIndex].brand_url;
    data.store = this.brandName;
    data.source = "mosc";
    data.couponVisibility = coupon_visibility_all
      ? "COUPON_VISIBLE_ALL"
      : "COUPON_VISIBLE_SELECTED";
    data.id = Math.floor(100000 + Math.random() * 900000);
    if (coupon_visibility_all && this.selectedProducts.length == 0) {
      this.sendCoupon(data);
    } else {
      this.selectedProducts.map((e) => {
        return Object.assign(e, data);
      });
      this.sendCoupons();
    }
  }
  sendCoupon(coupon) {
    this.product_service
      .addCoupon(coupon, this.brandId)
      .then(() => {
        this.couponAlert();
      })
      .catch((err) => {
        if (err.message) this.toastr.showError(err.message);
        else this.toastr.showError(err);
      });
  }
  sendCoupons() {
    this.product_service
      .addCoupons(this.selectedProducts, this.brandId)
      .then(() => {
        this.cartItem.map((cart) =>
          this.selectedProducts.some((product) => {
            if (product.cartId == cart.id) {
              let coupon = {
                code: product.code,
                end_date: product.end_date,
              };
              Object.assign(cart, { coupon: coupon });
            }
          })
        );
        this.couponForm.reset();
        Object.keys(this.cf).forEach((key) => {
          this.couponForm.get(key).setErrors(null);
        });
        this.couponAlert();
      })
      .catch((err) => {
        if (err.message) this.toastr.showError(err.message);
        else this.toastr.showError(err);
      });
  }
  couponAlert() {
    var parent = this;
    this.couponApplied = true;
    setTimeout(
      function () {
        parent.couponApplied = false;
      }.bind(this),
      3000
    );
  }

  changeBrand(index) {
    this.brandIndex = index;
    localStorage.setItem("brandIndex", index);
    this.setBrandData();
    this.closeBrandsModal();
  }
  closeBrandsModal() {
    var popup = document.getElementById("brandsModalBtn") as HTMLInputElement;
    if (popup != null) popup.click();
  }

  // Modal
  deleteNotification(notification) {
    this.coupons.splice(this.coupons.indexOf(notification), 1);
    var cart = this.cartItem.find((x) => x.coupon?.id == notification.id);
    if (cart != null) delete cart["coupon"];
    this.product_service
      .deleteCoupon(this.brandId, notification)
      .then((res) => {
        if (res) {
          console.log(res);
        } else {
          this.toastr.showError("Something went wrong! try again");
        }
      });
  }

  showCouponForm() {
    this.isCouponBarVisible = !this.isCouponBarVisible;
    if (this.isCouponBarVisible)
      this.renderer.addClass(document.body, "right-form-open");
    else this.renderer.removeClass(document.body, "right-form-open");
  }

  isCouponExpired(date) {
    return new Date().getTime() > date;
  }
}
