import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot,CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    if (this.authService.authenticated) {
      this.authService.isEmailVerified(1);
      return true;
    }
    this.router.navigate(['/']);
    return false;
}
}
