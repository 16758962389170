<header class="dashboard-header">
  <div class="container">
      <div class="dashboard-header-inner">

          <div class="dashboard-logo"><img class="circle" src={{brandLogo}} alt={{brandName}}>
            <span class="select-brand" data-toggle="modal" id="brandsModalBtn"
            *ngIf="isSuperAdmin" data-target="#brandsModal">Select Brand</span></div>

          <div class="dashboard-header-right dropdown">
              <button class="btn btn-primary header-logout-button dropdown-toggle" data-toggle="dropdown">
                  <i class="fa fa-user-circle" aria-hidden="true"></i> Action
              </button>

              <div class="dropdown-menu">
                <button class="dropdown-item" [routerLink]="['/change-password']"> {{'CHANGE_PASSWORD'|translate}}</button>
                <button class="dropdown-item" (click)='logout()'>{{'LOG_OUT'|translate}}</button>
              </div>

          </div>

      </div>

  </div>
</header>
<div class="lower-dashboard-contain">
  <div class="container">
    <div class="row">

      <div class="left-side-expendtoggle">
        <div class="dashboard-table-contain">

          <div class="add-filter-main">
            <button class="filter-button" (click)="updateItems()">
              <i class="fa fa-refresh"></i>
            </button>
          </div>

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#dashboard-tab-1" role="tab"
                aria-controls="dashboard-tab-1" aria-selected="true">{{'YOUR_PRODUCTS'|translate}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="white-space:pre-wrap; word-wrap:break-word" id="profile-tab" data-toggle="tab"
                href="#dashboard-tab-2" role="tab" aria-controls="dashboard-tab-2"
                aria-selected="false">{{'POSITIONS'|translate}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#dashboard-tab-3" role="tab"
                aria-controls="dashboard-tab-2" aria-selected="false">{{'COUPONS'|translate}}</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="dashboard-tab-1" role="tabpanel"
              aria-labelledby="dashboard-tab-1">
              <div class="table-responsive">
                <input type="text" [(ngModel)]="searchTerm">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="checkbox-col" scope="col">
                        <div class="checkbox-contain">
                          <input type="checkbox" (change)="selectAll($event)" />
                          <span class="checkmark"></span>
                        </div>
                      </th>
                      <th scope="col">{{'PRODUCT'|translate}}
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-up" id="nameAsc"
                          *ngIf="cartItem.length>0"></i>
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-down sorting"
                          *ngIf="cartItem.length>0" id="nameDesc"></i>
                      </th>
                      <th scope="col">{{'PRICE'|translate}}
                        <i appSortParams (param)="setSortParams($event)" *ngIf="cartItem.length>0"
                          class="fa fa-arrow-up" id="priceAsc"></i>
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-down sorting"
                          *ngIf="cartItem.length>0" id="priceDesc"></i>
                      </th>
                      <th scope="col">{{'NEW_PRICE'|translate}}
                        <i appSortParams (param)="setSortParams($event)" *ngIf="cartItem.length>0"
                          class="fa fa-arrow-up" id="newPriceAsc"></i>
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-down sorting"
                          *ngIf="cartItem.length>0" id="newPriceDesc"></i>
                      </th>
                      <th scope="col">{{'UPDATEDDATE'|translate}}
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-up" id="dateAsc"
                          *ngIf="cartItem.length>0"></i>
                        <i appSortParams (param)="setSortParams($event)" class="fa fa-arrow-down sorting"
                          *ngIf="cartItem.length>0" id="dateDesc"></i>
                      </th>
                      <th scope="col">{{'COUPON_CODE_SENT'|translate}}</th>
                      <th scope="col">{{'COUPON_CODE_VALIDITY'|translate}}</th>
                      <th scope="col">{{'USER_ID'|translate}}</th>
                      <th scope="col">{{'URL'|translate}}</th>
                    </tr>
                  </thead>

                  <tbody *ngIf="cartItem.length>0">
                    <tr *ngFor="let item of cartItem |filter:searchTerm| sort:direction:column:type">
                      <td class="checkbox-col">
                        <div class="checkbox-contain">
                          <input type="checkbox" (change)="selectProduct($event,item.id,item.itemBelongsToUsers)"
                            [checked]="allSelected" />
                          <span class="checkmark"></span>
                        </div>
                      </td>
                      <td class="dark-column">{{item.name}}</td>
                      <td class="dark-column">{{item.currency=="¤"?"€":item.currency}} {{item.price | number:'1.2-2'}}</td>
                      <td class="dark-column">{{(item.updatedPrice>0?item.currency:"")}} {{(item.updatedPrice>0?item.updatedPrice:"") | number:'1.2-2'}}</td>
                      <td class="">{{item.updatedAt|date:'dd/MM/yyyy'}}</td>
                      <td class="">{{item.coupon?.code||""}}</td>
                      <td class="">{{item.coupon?.end_date|date:'dd/MM/yyyy'||""}}</td>
                      <td class="">{{item.itemBelongsToUsers}}</td>
                      <td class=""><a href={{item.itemUrl}} target="_blank">{{item.itemUrl}}</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-pane fade" id="dashboard-tab-2" role="tabpanel" aria-labelledby="dashboard-tab-2">

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{'POSITION'|translate}}</th>
                      <th scope="col">{{'SHOP_NAME'|translate}}</th>
                      <th scope="col">{{'START_DATE'|translate}}</th>
                      <th scope="col">{{'END_DATE'|translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="">#{{brandPosition}}</td>
                      <td class="">{{brandName}}</td>
                      <td class="">{{brandStartDate|date:'dd/MM/yyyy'}}</td>
                      <td class="">{{brandEndDate|date:'dd/MM/yyyy'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-pane fade show" id="dashboard-tab-3" role="tabpanel" aria-labelledby="dashboard-tab-3">
              <div class="table-responsive">

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">Coupon
                      </th>
                      <th scope="col">{{'VALIDITYDATE'|translate}}
                      <th scope="col">{{'COUPONVISIBILITY'|translate}}
                      </th>
                      <th scope="col">Message</th>
                      <th scope="col">Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody *ngIf="coupons.length>0">
                    <tr *ngFor="let item of coupons |sort:direction:column:type">
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)">{{item.id||""}}</td>
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)">{{item.code||""}}</td>
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)">
                        {{item.end_date|date:'dd/MM/yyyy'}}</td>
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)">
                        {{(item.couponVisibility=="COUPON_VISIBLE_SELECTED"?'VISIBLE_TO_SELECTED':'VISIBLE_TO_ALL')|translate}}
                      </td>
                      <td style="white-space:pre-wrap; word-wrap:break-word"
                        [class.expired]="isCouponExpired(item.end_date)">
                        <p>{{item.message||""}}</p>
                      </td>
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)">
                        {{item.code?"Coupon":"Deal"}}</td>
                      <td class="dark-column" [class.expired]="isCouponExpired(item.end_date)"><a class="text-danger"
                          (click)="deleteNotification(item)"><i class="fa fa-trash pl-2" ngbTooltip="Delete"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="right-side-expendtoggle">
        <button id="collapseRightForm-button" class="white-arrow-button" (click)="showCouponForm()"><i
            class="fa fa-angle-left" aria-hidden="true"></i>
        </button>
        <div class="send-coupon-form">
          <form [formGroup]="couponForm" (ngSubmit)="onCouponSubmit()">

            <div class="form-group">
              <select class="form-control" (change)="setCoupon($event.target.value)">
                <option value="">Select Coupon</option>
                <option *ngFor="let coupon of coupons" value="{{coupon.id}}">{{coupon.id}}</option>
              </select>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" placeholder="{{'WRITE_CODE'|translate}}" formControlName="code">
            </div>

            <div class="form-group">
              <label class="form-title" for="">{{'ADD_VALIDITY_DATE'|translate}}</label>
              <div class="date-field">
                <input type="date" id="validity" class="date" formControlName="end_date">
              </div>
              <span class="errornote" *ngIf="submitted && cf.end_date.errors">{{'ENTER_DATE'|translate}}</span>
            </div>

            <div class="form-group">
              <textarea class="form-control" placeholder="{{'DEAL_MESSAGE'|translate}}"
                formControlName="message"></textarea>
              <span class="errornote" *ngIf="submitted && cf.message.errors">{{'DEAL_MESSAGE'|translate}}</span> </div>

            <div class="form-group">
              <p class="coupon-text">{{'CODE_MESSAGE'|translate}}<br>{{'CODE_ALL_USER_MESSAGE'|translate}}</p>

              <!-- On Mathiue Request

                <span class="coupon-text">
                <div class="checkbox-contain">
                  <input type="checkbox" formControlName="couponVisibility" />
                  <span class="checkmark"></span>
                </div>
                <span>{{'GLOBELOFFER'|translate}}</span>
              </span> -->
            </div>

            <button class="btn btn-primary btn-blue">{{'SEND_COUPON'|translate}}</button>

          </form>
        </div>

      </div>


    </div>

  </div>
</div>

<!-- Alert Message  -->
<div class="alert-contain success" *ngIf='couponApplied'>
  <div class="alert-box"><i class="icon-check-round"></i>{{'COUPON_APPLIED'|translate}}</div>
</div>

<!-- Modal -->
<div class="modal fade" id="brandsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" *ngIf="isSuperAdmin">
  <div class="modal-dialog lg-model" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select Brand</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">&nbsp;</th>
                <th scope="col">No.</th>
                <th scope="col">Brand</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                              let user of user.brands
                              let i = index
                            ">
                <th scope="row">
                  <div class="checkbox-contain">
                    <input type="checkbox" [checked]="i==brandIndex" (change)="changeBrand(i)" />
                    <span class="checkmark"></span>
                  </div>
                </th>
                <td>{{ i + 1 }}</td>
                <td>{{ user.brand_name }}</td>
                <td>{{ user.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
