import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-verification-screen",
  templateUrl: "./verification-screen.component.html",
  styleUrls: ["./verification-screen.component.css"],
})
export class VerificationScreenComponent implements OnInit {
  constructor( public route: Router,public auth: AuthService,) {
  }
    

  ngOnInit(): void {
    
  }

  login(){
    this.auth.isEmailVerified(0);
  }
}
