<section class="login-bg">
  <div class="login-main-contain">
    <div class="login-box-main">
      <div class="login-box-inner">
        <div class="login-logo">
          <img src="assets/images/moso-logo.png" alt="MOSC">
        </div>
        <div class="login-form-contain">
          <h3 class="login-box-title">{{ 'LOGINTITLE'| translate }}</h3>
          <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
            <div class="form-group">
              <input type="email" class="form-control" formControlName="email" id="username"
                placeholder="{{'ENTER_YOUR_EMAIL'|translate}}">
              <div class="alert alert-danger" role="alert" *ngIf="submitted && lf.email.errors">
                {{ 'EMAILERROR'| translate }}
              </div>
            </div>
            <div class="form-group pwd">
              <input [type]="show?'text':'password'" class="form-control" formControlName="password" id="password"
                placeholder="{{'ENTER_YOUR_PASSWORD' | translate}}">
              <span class="p-viewer">
                <i class="fa fa-eye" aria-hidden="true" (click)='password()' *ngIf="!show"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" (click)='password()' *ngIf="show"></i>
              </span>
              <div class="alert alert-danger" role="alert" *ngIf="submitted && lf.password.errors">
                {{ 'PWDERROR'| translate }}
              </div>
            </div>
            <div class="form-group">
              <p class="form-contact-text">{{'LOGINQUERY'|translate}}<br>
                <a href="{{'WEBSITE'|translate}} " target="_blank"><b> {{'CONTACT'|translate}}</b></a></p>
            </div>
            <button type="submit" class="btn btn-primary btn-blue1">{{'LOGIN'|translate}}</button>
            <button type="button" class="btn btn-primary btn-green" data-toggle="modal" id="requestModalBtn"
              data-target="#RequestModal">{{'REQUESTACCESS'|translate}}</button>
            <!-- <button type="button" style="display: none;" data-toggle="modal" id="verifyModalBtn"
              data-target="#verifyModal"></button> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Request Access Modal -->
<div class="modal fade" id="RequestModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'REQUESTACCESS'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="requestForm" (ngSubmit)="onRequestAccess()">
          <div class="form-group">
            <input type="email" formControlName="requestemail" autofocus class="form-control"
              placeholder="{{'EMAIL'|translate}}" required>
            <div class="alert alert-danger" role="alert" *ngIf="requestsubmitted && rf.requestemail.errors">
              {{ 'EMAILERROR'| translate }}
            </div>
          </div>

          <div class="form-group">
            <input type="text" formControlName="name" autofocus class="form-control" placeholder="{{'NAME'|translate}}"
              required="">
            <div class="alert alert-danger" role="alert" *ngIf="requestsubmitted && rf.name.errors">
              {{ 'NAMERROR'| translate }}
            </div>
          </div>

          <div class="form-group">
            <input type="text" formControlName="brandname" autofocus class="form-control"
              placeholder="{{'COMPANYNAME'|translate}}" required="">
            <div class="alert alert-danger" role="alert" *ngIf="requestsubmitted && rf.name.errors">
              {{ 'NAMERROR'| translate }}
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'CLOSE'|translate}}</button> -->
            <button type="submit" class="btn btn-primary btn-green">{{'SUBMIT'|translate}}</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<!-- sendLink Modal
<div class="modal fade" id="verifyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'VERIFY_EMAIL'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="declineVerification()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{'VERIFICATION_ALERT'|translate}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="declineVerification()">{{'CANCEL'|translate}}</button>
        <button type="button" class="btn btn-primary" (click)="sendEmail()">{{'SEND'|translate}}</button>
      </div>
    </div>
  </div>
</div> -->
